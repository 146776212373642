import React from "react"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import SEO from "./seo"

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: "20px",
    marginBottom: "20px",
  },
  title: {
    textAlign: "center",
  },
}))

export default function ({ children, pageContext }) {
  const { title } = pageContext && pageContext.frontmatter
  const classes = useStyles()

  return (
    <>
      {title && <SEO title={title} />}
      <Container className={classes.root} maxWidth="md">
        {title && <h1 className={classes.title}>{title}</h1>}
        {children}
      </Container>
    </>
  )
}
